<template>
  <v-app>
    <v-navigation-drawer
      v-if="sessionUser()"
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <Navigation />
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      fixed
      app
      color="white"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        style="width: 300px; font-size: 16px"
        class="ml-0 pl-4"
      >
        <span class="hidden-sm-and-down">{{ pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-progress-circular
        v-if="progress"
        indeterminate
        color="primary"
        class="mr-4"
      ></v-progress-circular>
      <v-btn
        v-if="notification"
        icon
        color="warning"
        @click="$router.push('/ticket', () => {}); notification=false"
      >
        <v-icon>mdi-email-alert</v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
        color="success"
      >
        <v-icon>mdi-email-check</v-icon>
      </v-btn>
      <v-toolbar-title
        v-if="sessionUser()"
        style="font-size: 16px"
        class="mr-2"
      >
        <span class="hidden-sm-and-down">{{ sessionUser().email }}</span>
      </v-toolbar-title>

      <v-menu
        offset-y
        bottom
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            outlined
            v-bind="attrs"
            v-on="on"
            class="mr-2"
          >
            {{ $t("lbl.lang") }}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="$i18n.locale"
            color="primary"
            mandatory
          >
            <v-list-item
              v-for="(lang, i) in langs"
              :key="i"
              :value="lang.value"
            >
              <v-list-item-content>
                <v-list-item-title v-text="lang.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <template v-if="!sessionUser()">
        <v-btn
          :ripple="false"
          outlined
          @click="$router.push('/login', () => {})"
          class="mr-2"
        >{{
          $t("lbl.login")
        }}</v-btn>

        <v-btn
          :ripple="false"
          outlined
          @click="$router.push('/register', () => {})"
        >{{
          $t("lbl.register")
        }}</v-btn>
      </template>
      <template v-else>
        <v-btn
          :ripple="false"
          outlined
          @click="$router.push('/profile', () => {})"
          class="mr-2"
        >{{
          $t("lbl.profile")
        }}</v-btn>

        <v-btn
          :ripple="false"
          outlined
          @click="logoutUser"
          class="mr-2"
        >{{
          $t("lbl.logout")
        }}</v-btn>
      </template>

      <div v-if="false">
        <v-btn icon>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-apps</v-icon>
        </v-btn>
        <v-btn
          icon
          large
        >
          <v-avatar
            size="32px"
            item
          >
            <v-img
              src="https://cdn.vuetifyjs.com/images/logos/logo.svg"
              alt="Vuetify"
            ></v-img>
          </v-avatar>
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-footer
      :fixed="fixed"
      app
    >
      <v-spacer></v-spacer>
      <span>
        <v-btn
          icon
          tile
          href="https://www.instagram.com/kadastro3b/"
          target="_blank"
        >
          <v-icon color="#bc2a8d">
            mdi-instagram
          </v-icon>
        </v-btn>
      </span>
      <span>
        <v-btn
          icon
          tile
          href="https://twitter.com/kadastro3b"
          target="_blank"
        >
          <v-icon color="#00aced">
            mdi-twitter
          </v-icon>
        </v-btn>
      </span>
      <span>
        <v-btn
          icon
          tile
          href="https://www.youtube.com/@kadastro3b/playlists"
          target="_blank"
        >
          <v-icon color="#bb0000">
            mdi-youtube
          </v-icon>
        </v-btn>
      </span>
      <span>
        <v-btn
          icon
          tile
          href="https://api.whatsapp.com/send?phone=905376829217"
          target="_blank"
        >
          <v-icon color="#4dc247">
            mdi-whatsapp
          </v-icon>
        </v-btn>
      </span>
      <span>
        0537 682 92 17
      </span>
      <v-spacer></v-spacer>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Settings } from "luxon";
import Navigation from "@/components/Navigation.vue";

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      clipped: true,
      drawer: true,
      fixed: true,
      miniVariant: false,
      progress: false,
      overlay: false,
      dialog: false,
      notification: false,
      langs: [
        { title: "Türkçe", value: "tr" },
        { title: "English", value: "en" },
      ],
      reload: false,
    };
  },
  computed: {
    ...mapState(["pageTitle"]),
  },
  watch: {
    "$i18n.locale": function _(val) {
      localStorage.setItem("lang", val);
      this.$vuetify.lang.current = this.$i18n.locale;
      Settings.defaultLocale = this.$i18n.locale;
      const routeTitle = this.$route.matched
        .filter((x) => x.meta.title)
        .map((x) => this.$t(x.meta.title))
        .join(" - ");
      this.$store.commit("setTitle", routeTitle || this.$t("lbl.company"));
    },
  },
  sockets: {
    client() {
      console.log("connected");
      const { id: userId } = this.sessionUser() || {};
      this.$socket.emit("client", userId);
    },
    ticket() {
      console.log("ticket submitted");
      this.notification = true;
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    logoutUser() {
      return this.logout().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style>
.focusfix:focus::before {
  opacity: 0 !important;
}
</style>
